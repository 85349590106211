import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import NextIcon from '@material-ui/icons/SkipNext';
import PreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayIcon from '@material-ui/icons/PlayArrow';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// import { isIos } from '../Utils/isIos';
import { fontColor } from '../Utils/constants';

const useStyles = makeStyles((theme) => ({
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
    //top: theme.spacing(1),
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    //padding: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  songInfo: {
    paddingLeft: '24px',
    marginBottom: theme.spacing(0.5),
  },
  button: {
    margin: theme.spacing(2),
    color: fontColor,
  },
  centered: {
    textAlign: 'center',
  },
}));

function KhaitaPlayerSkeleton() {
  const classes = useStyles();
  return (
    <>
      <SongInfoSkeleton />
      <div className={classes.playerWrapper}>
        <Skeleton
          variant='rect'
          animation={'wave'}
          height='100%'
          width='100%'
          className={classes.reactPlayer}
        />
      </div>
      <PlayerControlsSkeleton />
      <CurrentSongLyricsSkeleton />
    </>
  );
}

function PlayerControlsSkeleton() {
  return (
    <div style={{ textAlign: 'center' }}>
      <IconButton disabled={true}>
        <PreviousIcon />
      </IconButton>
      <IconButton>
        <PlayIcon />
      </IconButton>
      <IconButton>
        <NextIcon />
      </IconButton>
    </div>
  );
  /*if (isIos()) {
        return (
            <div style={{textAlign: 'center'}}>
                <IconButton disabled={true}>
                    <PreviousIcon/>
                </IconButton>
                <IconButton >
                    <PlayIcon/>
                </IconButton>
                <IconButton>
                    <NextIcon/>
                </IconButton>
            </div>
        ) 
    } else {
        return null;
    }*/
}

function CurrentSongLyricsSkeleton() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      //className={classes.paper}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography color='textSecondary' variant='overline'>
          LYRICS
        </Typography>
      </AccordionSummary>
      <AccordionDetails></AccordionDetails>
    </Accordion>
  );
}

function SongInfoSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.songInfo}>
      <Skeleton
        variant='text'
        animation={'wave'}
        height={'2.3em'}
        width='50%'
      />
      <Skeleton
        variant='text'
        animation={'wave'}
        height={'1.5em'}
        width='40%'
      />
    </div>
  );
}

function GoToButtonSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.centered}>
      <Button variant='outlined' underline='none' className={classes.button}>
        Go to the songs's page
      </Button>
    </div>
  );
}

export { KhaitaPlayerSkeleton, SongInfoSkeleton, GoToButtonSkeleton };
