import React, { Component, Fragment, useEffect } from 'react';
import {
  Grid,
  Typography,
  IconButton,
  AppBar,
  Toolbar,
  Button,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  NavLink,
  withRouter,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';
import { Helmet } from 'react-helmet';

import { db } from '../firebase';
import { backgroundColor, fontColor } from '../Utils/constants';

import SongVideo from './SongVideo';
import Lyrics from './Lyrics';
import Commentary from './Commentary';
import ShareUrl from '../Utils/ShareUrl';
import AddMyKhaita from '../MyKhaita/AddMyKhaita';
import { getLocalAutoplay } from '../KhaitaPlayer/AutoplayLocal';
import SongInfo from './SongInfo';
import AutoplaySwitch from '../KhaitaPlayer/AutoplaySwitch';
import { isIos } from '../Utils/isIos';

const styles = {
  root: {
    //padding: '15px',
    padding: '1.5em',
    boxShadow: ' 0 0 4px 0 rgba(0, 0, 0, 0.12)',
    backgroundColor: backgroundColor,
    minHeight: '100vh',
    paddingBottom: '4em',
  },
  icons: {
    color: fontColor,
  },
  progressRoot: {
    textAlign: 'center',
    padding: '20%',
  },
  progress: {
    display: 'inline-block',
    margin: 'auto',
    width: '100%',
    height: '100%',
  },
  navButton: {
    margin: '0.3em',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: backgroundColor,
    color: fontColor,
  },
  grow: {
    flexGrow: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  rootOnScroll: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  arrowButton: {
    left: theme.spacing(1),
    color: fontColor,
    flex: 1,
  },
  divArrowButton: {
    display: 'inline-block',
    color: fontColor,
    float: 'left',
    marginTop: theme.spacing(0),
  },
  songTitle: {
    display: 'block',
    color: fontColor,
    textAlign: 'right',
    //marginRight: theme.spacing(3),
    marginRight: 0,
    marginLeft: 'auto',
  },
  icons: {
    color: fontColor,
  },
  typography: {
    padding: theme.spacing(2),
  },
  dialogTitleCopyUrl: {
    marginLeft: theme.spacing(2),
    flex: 1,
    flexGrow: 1,
    textAlign: 'left',
  },
  numColl: {
    display: 'inline',
  },
  titles: {
    marginRight: theme.spacing(2),
  },
}));

function SongTitle(props) {
  const classes = useStyles();
  let history = useHistory();
  // const [linkBack, setLinkBack] = useState(props.linkBack);

  function goBack() {
    if (props.lastLocationState !== undefined) {
      history.push(props.linkBack, props.lastLocationState);
    } else {
      history.push(
        //linkBack,
        props.linkBack,
        //{lastSong: props.numColl}
        { lastSong: props.songUrl }
      );
    }
  }

  return (
    <div>
      <ScrollToTop />
      <div className={classes.divArrowButton}>
        <IconButton
          edge='start'
          className={classes.arrowButton}
          onClick={goBack}
        >
          <ArrowBackIcon />
        </IconButton>
      </div>
      {props.title === null ? null : (
        <div className={classes.songTitle}>
          <Typography
            variant='body2'
            component='p'
            color='textSecondary'
            className={classes.numColl}
          >
            {props.numColl}
          </Typography>
          <SongInfo
            songUrl={props.songUrl}
            songTitle={props.title.tra}
            by={props.by.tra}
          />
          <Typography variant='h5' component='p' className={classes.titles}>
            {props.title.tra}
          </Typography>
          <Typography variant='h6' component='p' className={classes.titles}>
            {props.title.tib}
          </Typography>
          <Typography variant='h6' component='p' className={classes.titles}>
            {props.title.eng}
          </Typography>
          <Typography
            variant='body1'
            component='p'
            color='textSecondary'
            className={classes.titles}
          >
            {props.by.tra}
          </Typography>
          <Typography
            variant='body1'
            component='p'
            color='textSecondary'
            className={classes.titles}
          >
            {props.by.tib}
          </Typography>
        </div>
      )}
    </div>
  );
}

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction='up' in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

async function fetchSongData(url) {
  const docRef = await db.doc('songs/' + url);
  //console.log('FETCH SONG DATA');

  return docRef
    .get()
    .then(async function (doc) {
      if (doc.exists) {
        return await doc.data();
      } else {
        // doc.data() will be undefined in this case
        //console.log("No such song!");
        return null;
      }
    })
    .catch(function (error) {
      //console.log("Error getting song data:", error);
      return null;
    });
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

class SongPage extends Component {
  constructor(props) {
    super(props);
    const songUrl = this.props.match.params.url;
    this.findLinks = this.findLinks.bind(this);
    this.findNumColl = this.findNumColl.bind(this);
    this.handleVideoEnd = this.handleVideoEnd.bind(this);
    this.scrollToRef = this.scrollToRef.bind(this);
    this.commRef = React.createRef();
    this.state = {
      songUrl: songUrl,
      title: null,
      by: null,
      video: null,
      textCommentary: null,
      lyricsLo: null,
      prevSong: '',
      nextSong: '',
      linkBack: '/',
      id: '',
      lyrics: undefined,
      redirect: false,
    };
  }

  async findLinks(url) {
    if (this.props.location.state === undefined) {
      return { prevSong: '', nextSong: '', linkBack: '/' };
    }
    const arrayLinks = this.props.location.state.navigation;
    const index = arrayLinks.indexOf(url);
    return {
      prevSong: index === 0 ? '' : arrayLinks[index - 1],
      nextSong: index === arrayLinks.length - 1 ? '' : arrayLinks[index + 1],
      linkBack: this.props.location.state.linkBack,
    };
  }

  findNumColl(url) {
    if (
      this.props.location.state === undefined ||
      this.props.location.state.songsNumColl === undefined
    ) {
      return '';
    } else {
      return this.props.location.state.songsNumColl[url];
    }
  }

  async updateState(url) {
    const songData = fetchSongData(url);
    const navigation = await this.findLinks(url);
    const numColl = this.findNumColl(url);
    songData.then((data) => {
      if (data === null) {
        return this.setState({
          songUrl: url,
          title: null,
          by: null,
          video: null,
          textCommentary: null,
          lyricsLo: null,
          prevSong: '',
          nextSong: '',
          linkBack:
            this.props.location.state === undefined ||
            this.props.location.state.linkBack === undefined
              ? '/'
              : this.props.location.state.linkBack,
          id: '',
          lyrics: undefined,
          numColl: '',
          redirect: false,
          valueTab: '',
        });
      } else {
        return this.setState({
          video: data.video,
          title: data.title,
          by: data.by,
          id: data.id,
          textCommentary: data.textCommentary,
          lyricsLo: data.lyricsLo,
          prevSong: navigation.prevSong,
          nextSong: navigation.nextSong,
          linkBack: navigation.linkBack,
          songUrl: url,
          lyrics: data.lyrics,
          numColl: numColl,
          redirect: false,
          valueTab: '',
        });
      }
    });
  }

  async componentDidMount() {
    const { url } = this.props.match.params;
    this.updateState(url);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.updateState(this.props.match.params.url);
    }
  }

  scrollToRef() {
    this.commRef.current.scrollIntoView(true);
  }

  handleVideoEnd = (valueTab) => {
    //console.log(this.state.songUrl, 'song page video end', this.state.prevSong);
    if (getLocalAutoplay()) {
      if (
        !(this.props.location.state === undefined || this.state.nextSong === '')
      ) {
        this.setState({ redirect: true, valueTab: valueTab });
      }
    }
  };

  render() {
    const { video, title, by, textCommentary, songUrl, lyrics } = this.state;

    //console.log('props', this.props);
    //console.log('state', this.state);

    //const tab = this.props.location.pathname.replace(this.props.match.url,'').replace('/','');
    const tab =
      this.props.location.state === undefined ||
      this.props.location.state.tab === undefined
        ? 'song'
        : this.props.location.state.tab;
    const hash = this.props.location.hash;

    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.nextSong,
            state: {
              navigation:
                this.props.location.state === undefined
                  ? undefined
                  : this.props.location.state.navigation,
              linkBack: this.state.linkBack,
              songsNumColl:
                this.props.location.state === undefined
                  ? undefined
                  : this.props.location.state.songsNumColl,
              tab: this.state.valueTab,
            },
          }}
        />
      );
    }

    return (
      <div className={this.props.classes.root}>
        <CssBaseline />
        {title === null ? (
          <Fragment>
            <SongTitle title={title} by={by} linkBack={this.state.linkBack} />
            <div className={this.props.classes.progressRoot}>
              <div className={this.props.classes.progress}>
                <CircularProgress variant='indeterminate' />
              </div>
            </div>
          </Fragment>
        ) : (
          <div>
            <Helmet>
              <title>
                Khaita. {title.tra} - {by.tra}
              </title>
              <meta
                name='description'
                content={`${title.tra} - ${by.tra}. Lyrics in Tibetan and Drajyor phonetic transcription, translations and commentaries. Modern Tibetan songs and dances collected by Chögyal Namkhai Norbu`}
              />
              <meta
                name='keywords'
                content={`${title.tra}, ${by.tra}, ${title.tib}, ${by.tib}, tibetan music, tibetan song, tibetan dance, drajyor, drayig, lyrics, translations, Chögyal Namkhai Norbu, Chogyal Namkhai Norbu, modern tibetan music, modern tibetan song, modern tibetan dance`}
              />
            </Helmet>
            <Grid container spacing={0}>
              <Grid item xs={12} md={6}>
                <SongTitle
                  title={title}
                  by={by}
                  linkBack={this.state.linkBack}
                  numColl={this.state.numColl}
                  songUrl={songUrl}
                  lastLocationState={
                    this.props.location.state === undefined
                      ? undefined
                      : this.props.location.state.lastLocationState
                  }
                />
                <SongVideo
                  video={video}
                  textCommentary={textCommentary}
                  tab={tab}
                  handleVideoEnd={this.handleVideoEnd}
                />
                {!isIos() && (
                  <Typography
                    align='right'
                    style={{ marginBottom: '20px' }}
                    color='textSecondary'
                  >
                    Autoplay
                    <AutoplaySwitch
                      state={this.props.state}
                      handleChange={this.props.handleChange}
                    />
                    {/*this.props.autoplayComponent*/}
                  </Typography>
                )}
                <div ref={this.commRef}>
                  <Commentary
                    textCommentary={textCommentary}
                    title={title.tra}
                    open={hash === '#commentary-text' ? true : false}
                    scrollToRef={this.scrollToRef}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Lyrics songUrl={songUrl} lyrics={lyrics} title={title.tra} />
              </Grid>
            </Grid>
          </div>
        )}
        <HideOnScroll {...this.props}>
          <AppBar
            position='fixed'
            color='primary'
            className={this.props.classes.appBar}
          >
            <Toolbar>
              <ShareUrl type='song' />
              <AddMyKhaita item={this.state} />
              <div className={this.props.classes.grow} />
              <Button
                color='inherit'
                component={NavLink}
                startIcon={<NavigateBeforeIcon />}
                disabled={
                  this.props.location.state === undefined ||
                  this.state.prevSong === ''
                    ? true
                    : false
                }
                to={{
                  pathname: this.state.prevSong,
                  state: {
                    navigation:
                      this.props.location.state === undefined
                        ? undefined
                        : this.props.location.state.navigation,
                    linkBack: this.state.linkBack,
                    songsNumColl:
                      this.props.location.state === undefined
                        ? undefined
                        : this.props.location.state.songsNumColl,
                    tab: tab,
                    lastLocationState:
                      this.props.location.state === undefined
                        ? undefined
                        : this.props.location.state.lastLocationState,
                  },
                }}
                size='small'
                className={this.props.classes.navButton}
              >
                Previous
              </Button>
              <Button
                edge='end'
                color='inherit'
                component={NavLink}
                endIcon={<NavigateNextIcon />}
                disabled={
                  this.props.location.state === undefined ||
                  this.state.nextSong === ''
                    ? true
                    : false
                }
                to={{
                  pathname: this.state.nextSong,
                  state: {
                    navigation:
                      this.props.location.state === undefined
                        ? undefined
                        : this.props.location.state.navigation,
                    linkBack: this.state.linkBack,
                    songsNumColl:
                      this.props.location.state === undefined
                        ? undefined
                        : this.props.location.state.songsNumColl,
                    tab: tab,
                    lastLocationState:
                      this.props.location.state === undefined
                        ? undefined
                        : this.props.location.state.lastLocationState,
                  },
                }}
                size='small'
                className={this.props.classes.navButton}
              >
                Next
              </Button>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(SongPage));
