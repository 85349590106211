import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { fontColor } from '../Utils/constants';
import { makeStyles } from '@material-ui/core/styles';
import Search from '../Search/Search';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundImage: `url('/android-chrome-192x192.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: theme.spacing(4),
  },
  menuButton: {
    marginRight: theme.spacing(0),
    color: fontColor,
  },
  searchButton: {
    color: fontColor,
  },
  hide: {
    display: 'none',
  },
  search: {
    flexGrow: 1,
    maxWidth: '600px',
  },
  flex: {
    flexGrow: 1,
  },
}));

const SearchToolbar = ({
  width,
  openSearch,
  setOpenSearch,
  toggleDrawer,
  open,
}) => {
  const classes = useStyles();

  return (
    <Toolbar>
      <ClickAwayListener
        onClickAway={() => {
          setOpenSearch(false);
        }}
      >
        <div style={{ display: 'contents' }}>
          <IconButton
            onClick={() => {
              setOpenSearch(!openSearch);
            }}
          >
            <ArrowBackIcon className={classes.searchButton} />
          </IconButton>
          <div className={classes.search}>
            <Search
              onClick={() => setOpenSearch(false)}
              closeSearch={() => setOpenSearch(false)}
            />
          </div>
          {/* <Bubbles /> */}
        </div>
      </ClickAwayListener>
    </Toolbar>
  );
};

export default SearchToolbar;
