import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
// import SearchIcon from '@material-ui/icons/Search';
import HomeIcon from '@material-ui/icons/HomeOutlined';

import KhaitaMain from './KhaitaMain';
import MainNavToolbar from './MainNavToolbar';
import SocialButtons from '../Info/SocialButtons';
import AutoplaySwitch from '../KhaitaPlayer/AutoplaySwitch';
import {
  getLocalAutoplay,
  setLocalAutoplay,
} from '../KhaitaPlayer/AutoplayLocal';
import { isIos } from '../Utils/isIos';
import UpdateCollections from '../Utils/UpdateCollections';

import { backgroundColor, fontColor } from '../Utils/constants';

const drawerWidth = 230;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    width: '100%',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
    backgroundColor: backgroundColor,
    zIndex: '100 !important',
  },
  openSearch: {
    zIndex: '400 !important',
  },
  toolbar: {
    backgroundImage: `url('/android-chrome-192x192.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: theme.spacing(4),
  },
  menuButton: {
    marginRight: theme.spacing(0),
    color: fontColor,
  },
  searchButton: {
    color: fontColor,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
  drawerHeaderMain: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    margin: '0',
    justifyContent: 'flex-end',
    backgroundColor: backgroundColor,
    color: fontColor,
  },
  content: {
    flexGrow: 1,
    padding: 0,
    width: '100%',
    marginTop: '64px',
    zIndex: 200,
    backgroundColor: backgroundColor,
    height: 'fit-content',
  },
  title: {
    flexGrow: 1,
    color: fontColor,
    textAlign: 'left',
  },
  menu: {
    paddingLeft: theme.spacing(2),
  },
  menuList: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
  socialButtons: {
    textAlign: 'center',
    marginBottom: theme.spacing(0),
  },
  version: {
    marginBottom: theme.spacing(4),
  },
}));

export default function MainNav(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  //Autoplay
  const [state, setState] = useState({
    autoplaySwitcher: getLocalAutoplay(),
  });

  useEffect(() => {
    setState({ ...state, autoplaySwitcher: getLocalAutoplay() });
  }, []);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    setLocalAutoplay(event.target.checked);
  };
  //Autoplay

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        color='secondary'
        className={clsx(
          classes.appBar,
          { [classes.appBarShift]: open },
          openSearch ? classes.openSearch : undefined
        )}
      >
        <MainNavToolbar
          toggleDrawer={toggleDrawer}
          open={open}
          openSearch={openSearch}
          setOpenSearch={setOpenSearch}
        />
      </AppBar>
      <Drawer
        className={classes.drawer}
        anchor='left'
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={toggleDrawer(false)}
        onClick={toggleDrawer(false)}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={toggleDrawer(false)}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <List onClick={toggleDrawer(false)} className={classes.menuList}>
          <ListItem
            button
            key='collections'
            component={Link}
            to={{ pathname: `/` }}
          >
            <ListItemText
              primary='Khaita collections'
              className={classes.menu}
            />
            <ListItemSecondaryAction>
              <HomeIcon className={classes.searchButton} />
            </ListItemSecondaryAction>
          </ListItem>
          {!isIos() && (
            <ListItem
              //button
              key='autoplay'
            >
              <ListItemText primary='Autoplay' className={classes.menu} />
              <ListItemSecondaryAction>
                <AutoplaySwitch state={state} handleChange={handleChange} />
                {/*{props.autoplayComponent}*/}
              </ListItemSecondaryAction>
            </ListItem>
          )}
          <ListItem
            button
            key='about'
            component={Link}
            to={{ pathname: `/about` }}
          >
            <ListItemText primary='About Khaita' className={classes.menu} />
          </ListItem>
          {/* <ListItem 
                        button 
                        key='aroundTheWorld' 
                        component={Link} 
                        to={{pathname: `/aroundtheworld`}}
                    >
                        <ListItemText primary='Around the World' className={classes.menu}/>
                    </ListItem> */}
          {/*<ListItem 
                        button 
                        key='danceschool' 
                        component={Link} 
                        to={{pathname: `/danceschool`}}
                    >
                        <ListItemText primary='Dance School' className={classes.menu}/>
                    </ListItem>*/}
          {/* <ListItem 
                        button 
                        key='calendar' 
                        component={Link} 
                        to={{pathname: `/calendar`}}
                    >
                        <ListItemText primary='Events calendar' className={classes.menu}/>
                    </ListItem> */}
          <ListItem
            button
            key='ourteam'
            component={Link}
            to={{ pathname: `/ourteam` }}
          >
            <ListItemText primary='Our team' className={classes.menu} />
          </ListItem>
          <ListItem
            button
            key='contact'
            component={Link}
            to={{ pathname: `/contact` }}
          >
            <ListItemText primary='Contact us' className={classes.menu} />
          </ListItem>
          <UpdateCollections classesMenu={classes.menu} />
          <Divider />
          {/*<ListItem 
                        button 
                        key='update' 
                        component={Link} 
                        to={{pathname: `/update`}}
                    >
                        <ListItemText primary='Update' className={classes.menu}/>
                    </ListItem> */}
          <ListItem
            button
            key='donate'
            component={Link}
            to={{ pathname: `/donate` }}
          >
            <ListItemText primary='Donate' className={classes.menu} />
          </ListItem>
        </List>
        <div className={classes.socialButtons}>
          <SocialButtons />
        </div>
        <Typography
          align='center'
          variant='caption'
          className={classes.version}
        >
          version 1.79
        </Typography>
      </Drawer>
      <main className={classes.content}>
        <KhaitaMain
          state={state}
          handleChange={handleChange}
          //autoplayComponent={props.autoplayComponent}
        />
      </main>
    </div>
  );
}
