import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
//import DialogContent from '@material-ui/core/DialogContent';
//import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton } from '@material-ui/core';
//import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import PlayIcon from '@material-ui/icons/PlayArrow';
import { makeStyles } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';

import VideoContainer from '../VideoContainer/VideoContainer';
import chunkArray from '../Utils/chunkArray';
// import Progress from '../Utils/Progress';
import { db } from '../firebase';

import { DialogTitle, DialogContent, Transition } from '../Utils/Dialog';

import { fontColor } from '../Utils/constants';
import { KhaitaPlayerSkeleton } from './KhaitaPlayerSkeleton';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: 0,
    overflowY: 'visible',
  },
  icons: {
    color: fontColor,
  },
  fullWidthDialog: {
    width: '100%',
    margin: '0px',
  },
  normalDialog: {
    borderRadius: '0px',
  },
  paperDialog: {
    margin: '0px',
    borderRadius: '0px',
  },
  paperScrollPaper: {
    maxHeight: '100%',
  },
}));

function KhaitaPlayer(props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { width } = props;

  const [links, setLinks] = useState([]);
  const [playlistData, setPlaylistData] = useState([]);

  function handleOpen() {
    updateSources(props.songList);
    setOpen(true);
  }

  async function updateSources() {
    let songsLinks = {};

    const songsArrays = chunkArray([...props.songList], 10);
    for (let array of songsArrays) {
      const songsLinksForArray = await findSongsLinks(array);
      songsLinks = {
        ...songsLinks,
        ...songsLinksForArray,
      };
    }

    const sources = [];
    const playlistData = [];
    for (let url of props.songList) {
      //debugger
      if (songsLinks[url] !== undefined) {
        sources.push(songsLinks[url].link);
        playlistData.push(songsLinks[url]);
      }
    }
    setLinks(sources);
    setPlaylistData(playlistData);
  }

  async function findSongsLinks(array) {
    let songsUrls = {};
    await db
      .collection('songs')
      .where('url', 'in', array)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          songsUrls = {
            ...songsUrls,
            [doc.data().url]: {
              link: doc.data().video.song,
              lyrics: doc.data().lyrics,
              title: doc.data().title.tra,
              by: doc.data().by.tra,
              url: doc.data().url,
            },
          };
        });
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });
    return songsUrls;
  }

  const handleClose = () => {
    setOpen(false);
    setLinks([]);
    setPlaylistData([]);
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label='player'
        className={classes.icons}
        onClick={handleOpen}
        size='medium'
      >
        <PlayIcon fontSize='large' color='action' />
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        classes={{
          paperFullWidth:
            width === 'xs' || width === 'sm'
              ? classes.fullWidthDialog
              : classes.normalDialog,
          paper: classes.paperDialog,
          paperScrollPaper: classes.paperScrollPaper,
        }}
      >
        <DialogTitle id='khaita-player' onClose={handleClose}>
          <Typography variant='body1' component='p' color='textSecondary'>
            {props.titleCollection}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {links.length === 0 && <KhaitaPlayerSkeleton />}
          {links.length > 0 && (
            <VideoContainer
              open={open}
              link={links}
              playlistData={playlistData}
              navigation={props.songList}
              songsNumColl={props.songsNumColl}
              linkBack={props.linkBack}
            />
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

KhaitaPlayer.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(KhaitaPlayer);
