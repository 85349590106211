import React, { Fragment, useState } from 'react';
import { Tabs, Tab, Box } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';

//import SongList from './SongList';
import TabContainer from './TabContainer';
import MyKhaita from '../../MyKhaita/MyKhaita';
import DzamlingGarSong from '../../DzamlingGarSong/DzamlingGarSong';

import {
  backgroundColor,
  fontColor,
  accentColor,
  khaitaColor,
} from '../../Utils/constants';

import { Switch, Route, Link, useRouteMatch, Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  topTab: {
    backgroundColor: backgroundColor,
    marginTop: '0',
    width: '100%',
    color: fontColor,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 4px 0 rgba(0, 0, 0, 0.12)',
  },
  tabContainer: {
    width: '100%',
  },
  progressRoot: {
    textAlign: 'center',
    padding: '20%',
  },
  progress: {
    display: 'inline-block',
    margin: 'auto',
    width: '100%',
    height: '100%',
  },

  myKhaita: {
    minWidth: 'fit-content',
  },
  inline: {
    display: 'inline',
    verticalAlign: 'middle',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    verticalAlign: 'middle',
  },
  grid: {
    wrap: 'nowrap',
  },
  myIcon: {
    display: 'block',
    textAlign: 'center',
    AlignItems: 'center',
    color: khaitaColor, //'#f50057',
  },
  myKhaitaLabel: {
    marginLeft: theme.spacing(1),
  },
}));

export default function TopCollectionTabs(props) {
  let { path, url } = useRouteMatch();

  const [value, setValue] = useState(
    props.tab === '' || props.tab === undefined
      ? 'mekhor'
      : props.tab.toLowerCase()
  );

  const classes = useStyles();

  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <Fragment>
      <StyledTabs
        value={value}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='on'
        className={classes.topTab}
      >
        <Tab
          value={'mykhaita'}
          key={'MK'}
          className={classes.myKhaita}
          aria-label='my-khaita'
          label={
            props.tab === 'mykhaita' ? (
              <Box alignItems='center' display='flex' className={classes.grid}>
                <Box>
                  <FavoriteIcon className={classes.myIcon} />
                </Box>
                <Box className={classes.myKhaitaLabel}>My Khaita</Box>
              </Box>
            ) : (
              <FavoriteIcon className={classes.myIcon} />
            )
          }
          component={Link}
          to={`${url}/mykhaita`}
        />
        <Tab
          value={'mekhor'}
          key={'M'}
          label={props.mekhor.title}
          component={Link}
          to={`${url}/mekhor`}
        />
        <Tab
          value={'barsam'}
          key={'B'}
          label={props.barsam.title}
          component={Link}
          to={`${url}/barsam`}
        />
        <Tab
          value={'message'}
          key={'T'}
          label={props.message.title}
          component={Link}
          to={`${url}/message`}
        />
        <Tab
          value={'circle'}
          key={'O'}
          label={props.other.title}
          component={Link}
          to={`${url}/circle`}
        />
        <Tab
          value={'dzamlinggar'}
          key={'D'}
          label={'Dzamling Gar'}
          component={Link}
          to={`${url}/dzamlinggar`}
        />
        {/* <Tab
          value={'comments'}
          key={'C'}
          label={'Comments'}
          component={Link}
          to={`${url}/comments`}
        /> */}
      </StyledTabs>
      <Switch>
        <Route
          path={`${path}/mykhaita`}
          key={'MK'}
          render={(routeProps) => <MyKhaita />}
        />
        <Route
          path={`${path}/mekhor`}
          key={'M'}
          render={(routeProps) => (
            <TabContainer
              {...routeProps}
              parentCollection='M'
              key={'M'}
              collections={props.mekhor.collections}
              className={classes.tabContainer}
            />
          )}
        />
        <Route
          path={`${path}/barsam`}
          key={'B'}
          render={(routeProps) => (
            <TabContainer
              {...routeProps}
              parentCollection='B'
              key={'B'}
              collections={props.barsam.collections}
              className={classes.tabContainer}
            />
          )}
        />
        <Route
          path={`${path}/message`}
          key={'T'}
          render={(routeProps) => (
            <TabContainer
              {...routeProps}
              parentCollection='T'
              key={'T'}
              collections={props.message.collections}
              className={classes.tabContainer}
            />
          )}
        />
        <Route
          path={`${path}/circle`}
          key={'O'}
          render={(routeProps) => (
            <TabContainer
              {...routeProps}
              parentCollection='O'
              key={'O'}
              collections={props.other.collections}
              className={classes.tabContainer}
            />
          )}
        />
        <Route
          path={`${path}/dzamlinggar`}
          key={'D'}
          render={(routeProps) => <DzamlingGarSong />}
        />
        {/* <Route
          path={`${path}/comments`}
          key={'CM'}
          render={(routeProps) => (
            <TabContainer
              {...routeProps}
              parentCollection='CM'
              key={'CM'}
              collections={props.comments.collections}
              className={classes.tabContainer}
            />
          )}
        /> */}
        <Redirect to={`${path}/mekhor`} />
      </Switch>
    </Fragment>
  );
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      width: '100%',
      borderRadius: '1px',
      backgroundColor: accentColor,
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);
