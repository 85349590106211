import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Picker from './Picker';
import { db } from '../firebase';
import SearchResultDialog from './SearchResultDialog';
import getUrlThumbnail from '../Utils/getUrlThumbnail';
import Bubbles from './Bubbles';

function Search(props) {
  const [songList, setSongList] = useState();
  const [openSearchResults, setOpenSearchResults] = useState(false);
  const [searchFor, setSearchFor] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (openSearchResults) {
      props.closeSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, props]);

  async function fetchSong(songId) {
    const docRef = db.doc('songs/' + songId);

    const doc = await docRef.get();
    if (doc.exists) {
      //console.log('return from server db', songCollection);
      return await doc.data();
      //return doc.data();
    } else {
      // doc.data() will be undefined in this case
      //console.log("No such document!", songCollection);
      return undefined;
    }
  }

  async function findSongsWithPerformer(id) {
    //find all collections with this performer
    let songsArray = [];
    //all songs with this performer
    await db
      .collection('songs')
      .where('byPerformer', '==', id)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          //console.log('doc', doc.data(), doc.id);
          songsArray.push({
            id: '',
            url: doc.data().url,
            title: doc.data().title,
            by: doc.data().by,
            imageUrl: getUrlThumbnail(doc.data().video.song),
          });
        });
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });
    return songsArray;
  }

  async function setSelectedData(selected) {
    var songListArray = [];
    //console.log('selected', selected);
    if (selected === null) {
      setSongList([]);
      setOpenSearchResults(false);
      setSearchFor('');
    } else {
      //var songListArray = [];
      if (selected.isSong) {
        //find songs
        const songData = fetchSong(selected.songPerformerId);
        songData.then((data) => {
          if (data === undefined) {
          } else {
            var song = {
              id: '',
              url: data.url,
              title: data.title,
              by: data.by,
              imageUrl: getUrlThumbnail(data.video.song),
            };
            songListArray.push(song);
            console.log(songListArray);
            setSongList(songListArray);
          }
        });
        //setOpen(true);
      } else {
        //performer
        songListArray = await findSongsWithPerformer(selected.songPerformerId);
        //console.log(songListArray);
        setSongList(songListArray);
        //setOpen(true);
      }
      setSearchFor(selected.title);
      setOpenSearchResults(true);
    }
  }

  const handleClose = () => {
    setOpenSearchResults(false);
    props.closeSearch();
  };

  const handleBubbleSearch = async (bubble) => {
    try {
      const songsArray = [];
      await db
        .collection('songs')
        .where('tags', 'array-contains', bubble)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            //console.log('doc', doc.data(), doc.id);
            songsArray.push({
              id: '',
              url: doc.data().url,
              title: doc.data().title,
              by: doc.data().by,
              imageUrl: getUrlThumbnail(doc.data().video.song),
            });
          });
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
        });
      if (songsArray.length > 0) {
        setSongList(songsArray);
        setOpenSearchResults(true);
        setSearchFor(bubble);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <Picker setSelectedData={setSelectedData} />
      <Bubbles handleBubbleSearch={handleBubbleSearch} />
      <SearchResultDialog
        songList={songList}
        openSearchResults={openSearchResults}
        handleClose={handleClose}
        title={searchFor}
        linkBack={location.pathname}
        lastLocationState={location.state}
      />
    </>
  );
}

export default Search;
