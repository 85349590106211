import React from 'react';
import { Tabs, Tab, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SongList from '../SongList/SongList';

import { Switch, Route, Link, useRouteMatch, Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(3),
    textAlign: 'center',
  },
}));

function isEmpty(obj) {
  for (var key in obj) {
    return false;
  }
  return true;
}

function getFirstId(obj) {
  const keys = Object.keys(obj);
  keys.sort();
  for (var key of keys) {
    return obj[key].id;
  }
  return undefined;
}

export default function TabContainer(props) {
  let { path, url } = useRouteMatch();
  const firstTab =
    (props.parentCollection === 'M' || props.parentCollection === 'CM') &&
    !(props.location.pathname.split('/')[3] === undefined)
      ? props.location.pathname.split('/')[3].toUpperCase()
      : props.parentCollection === 'CM'
      ? 'CM_MFT'
      : getFirstId(props.collections);

  const [value, setValueTab] = React.useState(firstTab);
  React.useEffect(() => {
    setValueTab(firstTab);
  }, [firstTab]);

  const classes = useStyles();

  var subCollections = [];
  if (props.parentCollection === 'CM') {
    subCollections.push(props.collections['CM_MFT']);
    subCollections.push(props.collections['CM_MEKHOR']);
    subCollections.push(props.collections['CM_OTHER']);
    subCollections.push(props.collections['CM_NEW']);
  } else {
    for (var key in props.collections) {
      subCollections.push(props.collections[key]);
    }

    subCollections.sort(function (a, b) {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      // a должно быть равным b
      return 0;
    });
  }

  var lastSong;
  if (props.location.state !== undefined) {
    lastSong = props.location.state.lastSong;
  } else {
    lastSong = undefined;
  }

  console.log('subCollections', subCollections);

  //check if this collection has only one layer of subcollections
  //or no subcollections at all
  //if yes - render SongList
  const firstSub = props.collections[getFirstId(props.collections)];
  console.log('firstSub', firstSub);
  if (
    props.collections === null ||
    isEmpty(props.collections) ||
    props.collections === undefined ||
    firstSub === undefined ||
    firstSub === null ||
    firstSub.collections === null ||
    firstSub.collections === undefined ||
    isEmpty(firstSub.collections)
  ) {
    if (subCollections.length === 0) {
      //no subcollections
      return (
        <SongList
          key={props.parentCollection}
          songCollection={props.parentCollection}
          linkBack={`${path}`}
          lastSong={lastSong}
        />
      );
    } else {
      return (
        <div>
          {subCollections.map((collection) => (
            <SongList
              key={collection.id}
              songCollection={collection.id}
              linkBack={`${path}`}
              lastSong={lastSong}
            />
          ))}
        </div>
      );
    }
  }

  const selectedParentCollection = props.parentCollection;

  function handleChange(event, newValue) {
    setValueTab(newValue);
  }

  if (props.collections === undefined) {
    return null;
  }

  return (
    <div>
      {props.parentCollection === 'CM' && (
        // <div>Commentaries of Namkhai Norbu</div>
        <Typography variant='h6' className={classes.title}>
          Songs with commentaries by Chögyal Namkhai Norbu
        </Typography>
      )}
      <Tabs
        value={value}
        variant='scrollable'
        scrollButtons='auto'
        onChange={handleChange}
      >
        {subCollections.map(
          ({ parentCollection, title, id }) =>
            parentCollection === selectedParentCollection && (
              <Tab
                value={id}
                key={id}
                label={title}
                component={Link}
                to={`${url}/${id}`}
              />
            )
        )}
      </Tabs>
      <Switch>
        {subCollections.map(({ id, collections }) => (
          <Route
            path={`${path}/${id}`}
            key={id}
            render={(routeProps) => (
              <TabContainer
                {...routeProps}
                key={id}
                parentCollection={id}
                collections={collections}
              />
            )}
          />
        ))}
        <Redirect to={`${path}/${firstTab}`} />
      </Switch>
    </div>
  );
}
