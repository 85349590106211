import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    //backgroundColor: theme.palette.primary.main,
    backgroundColor: '#198ab7', //'#0f8899',
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    height: '16px',
  },
  tinyIcon: {
    fontSize: '1rem',
  },
  tinyIconShare: {
    fontSize: '1rem',
    borderBottom: 'solid',
    borderRadius: '3px',
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby='client-snackbar'
      message={
        <span id='client-snackbar' className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <Button
          key='close'
          aria-label='close'
          color='inherit'
          onClick={onClose}
          endIcon={<CloseIcon />}
        >
          Got it
        </Button>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.object,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

function CustomizedSnackbars(props) {
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  useEffect(() => {
    const timer = setTimeout(() => setOpen(true), 6000);
    return () => clearTimeout(timer);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={360000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant='info'
          message={
            <Typography variant='caption'>
              Install the Khaita web app on your iPhone: tap the menu button{' '}
              <MenuIcon className={classes.tinyIcon} /> in the lower right
              corner of your screen, select “Share”{' '}
              <ArrowUpwardIcon className={classes.tinyIconShare} />, then tap
              “Add to Home Screen”{' '}
              <AddBoxOutlinedIcon className={classes.tinyIcon} />
            </Typography>
          }
        />
      </Snackbar>
    </div>
  );
}

export default function InstallNotification() {
  // Detects if device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };
  // Detects if device is mobile
  /*const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      //return /iphone|ipad|ipod/.test( userAgent );
      return /iphone|ipad|ipod|android/.test( userAgent );
  }*/
  // Detects if device is in standalone mode
  const isInStandaloneMode = () =>
    'standalone' in window.navigator && window.navigator.standalone;

  return (
    <Fragment>
      {isIos() && !isInStandaloneMode() ? <CustomizedSnackbars /> : null}
    </Fragment>
  );
}
