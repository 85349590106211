import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import GridList from '@material-ui/core/GridList';
import { ImageList as GridList } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import KhaitaPlayerSettings from '../../KhaitaPlayer/KhaitaPlayerSettings';

import SongCard from '../SongCard/SongCard';

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(2),
    marginTop: theme.spacing(5),
    //marginBottom: theme.spacing(4),
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    paddingRight: theme.spacing(2),
    margin: '1px !important',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleCollection: {
    marginLeft: theme.spacing(1),
    //flex: 1,
    display: 'inline-block',
    //display: 'table-cell',
    verticalAlign: 'text-top',
    marginTop: theme.spacing(1),
  },
  collectionControls: {
    //display: 'inline-block',
    display: 'inline-table',
    width: '100%',
  },
}));

export default function SingleLineCollection(props) {
  const classes = useStyles();
  const [songListArray, setSongListArray] = useState(props.songListArray);

  const handleDragStart = useCallback((e, index) => {
    e.dataTransfer.setData('text/plain', index);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleDrop = useCallback(
    (e, dropIndex) => {
      e.preventDefault();
      const dragIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);
      const newSongList = [...songListArray];
      const [reorderedItem] = newSongList.splice(dragIndex, 1);
      newSongList.splice(dropIndex, 0, reorderedItem);
      setSongListArray(newSongList);
      // You may want to call a prop function here to update the parent state
      // props.onReorder(newSongList);
    },
    [songListArray]
  );

  return (
    <div className={classes.root}>
      <div className={classes.collectionControls}>
        <Typography variant='h6' className={classes.titleCollection}>
          {props.titleCollection}
        </Typography>
        {props.songCollectionId === 'Search results' ||
        props.songCollectionId === 'Music in this video' ? null : (
          <KhaitaPlayerSettings
            onShuffle={props.onShuffle}
            onSort={props.onSort}
            isShuffled={props.isShuffled}
            songList={props.navigation}
            titleCollection={props.titleCollection}
            songsNumColl={props.songsNumColl}
            linkBack={props.linkBack}
          />
        )}
      </div>
      <div>
        <GridList className={classes.gridList} onDragOver={handleDragOver}>
          {songListArray.map((item, index) => (
            //   <div
            //     key={
            //       item.id === '' || item.id === undefined ? item.url : item.id
            //     }
            //     draggable
            //     onDragStart={(e) => handleDragStart(e, index)}
            //     onDrop={(e) => handleDrop(e, index)}
            //   >
            <SongCard
              key={item.id === '' || item.id === undefined ? item.url : item.id}
              item={item}
              navigation={props.navigation}
              linkBack={props.linkBack}
              songsNumColl={props.songsNumColl}
              lastSong={props.lastSong}
              index={index}
              lastLocationState={props.lastLocationState}
              handleDragStart={handleDragStart}
              handleDrop={handleDrop}
            />
            //   </div>
          ))}
        </GridList>
      </div>
    </div>
  );
}
