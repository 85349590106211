import React, { useState } from 'react';
import { Chip, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { accentColor, accentColor2 } from '../Utils/constants';

const useStyles = makeStyles((theme) => ({
  bubblesContainer: {
    position: 'absolute',
    top: '60px',
    left: '0px',
    right: '0px',
    // height: '400px',
    zIndex: 1300,
    backgroundColor: 'white',
    width: '100%',
    padding: '5px',
    boxShadow: `0px 1px 12px 0px rgba(0, 0, 0, 0.12)`,
  },
  bubble: {
    margin: '5px',
    padding: '8px, 16px, 8px, 16px',
    borderRadius: '10px',
  },
  button: {
    margin: theme.spacing(2),
    color: 'white',
    backgroundColor: accentColor,
    borderColor: accentColor,
    '&:hover': {
      backgroundColor: accentColor2,
      borderColor: accentColor2,
    },
  },
}));

const tags = [
  'Tibet',
  'Culture & History',
  'Language',
  'Children',
  'Unity & Commitment',
  'Spiritual teaching & Teaching holders',
  'Sacred places & Pilgrimage',
  'Nomad',
  'Environment',
  'Drinking songs',
  'Tashi',
];

const Bubbles = ({ handleBubbleSearch }) => {
  const classes = useStyles();
  const [selectedChip, setSelectedChip] = useState([]);

  const handleChipClick = (chip) => {
    setSelectedChip(chip);
    handleBubbleSearch(chip);
  };

  // const handleSearch = () => {
  //   if (selectedChips.length === 0) return;
  //   handleBubbleSearch(selectedChips);
  // };

  return (
    <div className={classes.bubblesContainer}>
      {tags.map((tag) => (
        <Chip
          variant='outlined'
          clickable
          key={tag}
          label={tag}
          className={classes.bubble}
          onClick={() => handleChipClick(tag)}
          color={selectedChip === tag ? 'primary' : 'default'}
        />
      ))}
      {/* {selectedChips.length > 0 && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            className={classes.button}
            variant='outlined'
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      )} */}
    </div>
  );
};

export default Bubbles;
