import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Container from '@material-ui/core/Container';
import { Toolbar, IconButton } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';

import ScrollableAnchor from 'react-scrollable-anchor';

import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import DialogContent from '@material-ui/core/DialogContent';

import ReactHtmlParser from 'react-html-parser';

import { backgroundColor, fontColor } from '../Utils/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  paper: {
    maxHeight: '90vh',
    overflowY: 'scroll',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  toolbar: {
    backgroundColor: backgroundColor,
    color: fontColor,
  },
  appBar: {
    position: 'relative',
    //boxShadow: 'none',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 4px 0 rgba(0, 0, 0, 0.12)',
    paddingRight: '0 !important',
  },
  lyricsPaper: {
    //maxHeight: '95vh',
    //overflowY: 'scroll',
    padding: theme.spacing(1),
    '& p': {
      marginTop: theme.spacing(1),
      lineHeight: '140%',
    },
    '& pre': {
      fontFamily: 'Times New Roman',
      // fontSize: '22px',
      lineHeight: '120%',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
    },
  },
  lyricsText: {
    overflowX: 'scroll',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    textAlign: 'left',
  },
  fullScreenButton: {
    textAlign: 'end',
    marginBottom: theme.spacing(2),
  },
  commentary: {
    width: '100%',
    '& p': {
      marginTop: theme.spacing(1),
      lineHeight: '140%',
    },
    '& pre': {
      fontFamily: 'Times New Roman',
      // fontSize: '22px',
      lineHeight: '120%',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
    },
    '& h6': {
      marginTop: theme.spacing(2),
      lineHeight: '140%',
      textAlign: 'center',
      overflowWrap: 'break-word',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function FullScreenMode(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //const html = `<div style="max-width: fit-content; max-width: max-content; margin:0 auto;"> ${props.html} </div>`;
  return (
    <div>
      <div className={classes.fullScreenButton}>
        <Button
          startIcon={<FullscreenIcon />}
          onClick={handleClickOpen}
          variant='outlined'
        >
          Full screen commentary
        </Button>
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Typography variant='h6' className={classes.title}>
              Chögyal Namkhai Norbu about <b>{props.title}</b> song
            </Typography>
            <IconButton
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Container className={classes.lyricsPaper}>{props.html}</Container>
        </DialogContent>
      </Dialog>
    </div>
  );
}

// const scrollToRef = (ref) => {
//     ref.current.scrollIntoView(true);
// }

function Commentary(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(props.open);
  const myRef = useRef(null);

  if (props.textCommentary.eng === '') {
    return null;
  }

  const { width } = props;
  // tibP = (width === 'xs') ? '23px' : '28px';
  // tibS = (width === 'xs') ? '15px' : '23px';

  const wesP = width === 'xs' ? '18px' : '22px';
  // wesS = (width === 'xs') ? '12px' : '18px';
  const html = ReactHtmlParser(
    `<div style="max-width: fit-content; max-width: max-content; margin:0 auto; font-family: 'Times New Roman'; font-size: ${wesP}; line-height: 120%"><pre>${props.textCommentary.eng}</pre></div>`
  );

  //console.log(props);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (isExpanded) {
      //scrollToRef(myRef);
      //props.scrollToRef();
    }
  };

  return (
    <div ref={myRef}>
      <ScrollableAnchor id={'commentary-text'}>
        <Container className={classes.container}>
          <Accordion
            expanded={expanded}
            onChange={handleChange}
            className={classes.paper}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography>Chögyal Namkhai Norbu about the song</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.commentary}>
                <FullScreenMode html={html} title={props.title} />
                {html}
              </div>
            </AccordionDetails>
          </Accordion>
        </Container>
      </ScrollableAnchor>
    </div>
  );
}

Commentary.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(Commentary);
