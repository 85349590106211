import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Typography } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';

import { DialogTitle, DialogContent, Transition } from '../Utils/Dialog';
import { backgroundColor, fontColor } from '../Utils/constants';
import SearchResult from './SearchResult';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px',
    //boxShadow: ' 0 0 4px 0 rgba(0, 0, 0, 0.12)',
    backgroundColor: backgroundColor,
    //minHeight: '100vh',
  },
  closeButton: {
    color: fontColor,
    display: 'block',
    textAlign: 'right',
    marginRight: theme.spacing(0),
    marginLeft: 'auto',
  },
  titleLeft: {
    display: 'inline-block',
    color: fontColor,
    textAlign: 'left',
    paddingTop: theme.spacing(1),
    flex: 1,
    float: 'left',
  },
  flex: {
    flex: 1,
  },

  fullWidthDialog: {
    width: '100%',
    margin: '0px',
  },
  normalDialog: {
    borderRadius: '0px',
    maxWidth: 'fit-content',
    minWidth: '50%',
  },
  paperDialog: {
    margin: '0px',
    borderRadius: '0px',
  },
  paperScrollPaper: {
    maxHeight: '100%',
  },
}));

function SearchResultDialog(props) {
  const classes = useStyles();
  const { width } = props;

  return (
    <Dialog
      open={props.openSearchResults}
      TransitionComponent={Transition}
      //keepMounted
      onClose={props.handleClose}
      fullWidth
      maxWidth='md'
      classes={{
        paperFullWidth:
          width === 'xs' || width === 'sm'
            ? classes.fullWidthDialog
            : classes.normalDialog,
        paper: classes.paperDialog,
        paperScrollPaper: classes.paperScrollPaper,
      }}
    >
      <DialogTitle id='khaita-player' onClose={props.handleClose}>
        <Typography variant='body1' component='p' color='textSecondary'>
          Search results for
        </Typography>
        {props.title}
      </DialogTitle>
      <DialogContent className={classes.root}>
        <SearchResult
          songList={props.songList}
          linkBack={props.linkBack}
          lastLocationState={props.lastLocationState}
        />
      </DialogContent>
    </Dialog>
  );
}

SearchResultDialog.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(SearchResultDialog);
