import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { db } from '../firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      border: 'none',
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },
    backgroundColor: 'rgba(235, 237, 238, 1)',
    borderRadius: '10px',
    border: 'none',
  },
}));

function fetchSongData() {
  const docRef = db.doc('serviceData/songsAll');
  return docRef
    .get()
    .then(async function (doc) {
      if (doc.exists) {
        return await doc.data();
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
      }
    })
    .catch(function (error) {
      console.log('Error getting document:', error);
      return error;
    });
}

function fetchPerformerData() {
  const docRef = db.doc('serviceData/performersAll');
  //console.log('FETCH DATA FOR LIST');
  return docRef
    .get()
    .then(async function (doc) {
      if (doc.exists) {
        return await doc.data();
        //suggections = doc.data();
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
      }
    })
    .catch(function (error) {
      console.log('Error getting document:', error);
      return error;
    });
}

function fetchSuggestions() {
  const listOfData = [];
  const songData = fetchSongData();
  songData.then((data) => {
    for (var key in data) {
      listOfData.push({
        title: data[key].tra,
        tib: data[key].tib,
        eng: data[key].eng,
        songPerformerId: key,
        group: 'Songs',
        isSong: true,
      });
    }
    if (listOfData.length > 0) {
      listOfData.sort((a, b) => a.title.localeCompare(b.title));
    }
  });
  const performerData = fetchPerformerData();
  performerData.then((data) => {
    for (var key in data) {
      listOfData.push({
        title: data[key].tra,
        tib: data[key].tib,
        eng: '',
        songPerformerId: key,
        group: 'Singers',
        isSong: false,
      });
    }
    if (listOfData.length > 0) {
      listOfData.sort((a, b) => a.title.localeCompare(b.title));
    }
  });
  return listOfData;
}

const options = fetchSuggestions();

export default function Picker(props) {
  function handleChange(event, value) {
    //console.log(value);
    props.setSelectedData(value);
  }

  const classes = useStyles();

  return (
    <Autocomplete
      size='small'
      id='searching-field'
      options={options.sort((a, b) => b.group.localeCompare(a.group))}
      groupBy={(option) => option.group}
      getOptionLabel={(option) =>
        `${option.title} / ${option.tib} ${option.eng === '' ? '' : ' / '}${
          option.eng
        }`
      }
      onChange={handleChange}
      clearOnEscape={true}
      classes={{
        root: classes.root,
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant='outlined'
            autoFocus={true}
            fullWidth={true}
            placeholder={'Search'}
          />
        );
      }}
    />
  );
}
